/* Collapse Styles */

.ant-collapse {
  & .ant-collapse-item {
    &.dashboard-collapse-panel {
      & .ant-collapse-header {
        padding: 0;
        white-space: pre;
        align-items: center;
      }

      & .ant-collapse-content {
        & .ant-collapse-content-box {
          padding: 12px 0px 0px 42px;
        }
      }
    }

    &.environment-collapse-panel {
      & .ant-collapse-header {
        padding: 0;
        white-space: pre;
        align-items: center;

        & .ant-collapse-arrow {
          right: 0;
        }
      }

      & .ant-collapse-content {
        & .ant-collapse-content-box {
          padding: 12px 0px 0px 0px;

          & .ant-list-item-action {
            margin: 0;
          }

          & .ant-list-split .ant-list-item:last-child {
            padding-bottom: 0.25rem;
          }
        }
      }
    }
  }
}
